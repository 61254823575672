<template>
  <b-container fluid="md" id="login-reset-container">
    <b-card bg-variant="light" class="align-items-center">
      <h1 class="text-center mb-5">{{ $t('passwordReset.headline') }}</h1>

      <b-container class="container flex-column">
        <p>{{ $t('passwordReset.info') }}</p>
        <label for="resetEmail" class="sr-only" aria-hidden="true">{{ $t('passwordReset.email') }}</label>
        <b-form-input
          id="resetEmail"
          type="email"
          name="userEmail"
          maxlength="255"
          v-model="userEmail"
          :state="userEmail.length > 0 ? emailIsValid : null"
          v-bind:placeholder="$t('passwordReset.email')"
          autofocus
          autocomplete="email"
          required
        />
        <b-button variant="primary" v-on:click="requestReset" class="w-100 mt-4 mb-2">{{
          $t('passwordReset.submit')
        }}</b-button>
      </b-container>
    </b-card>
  </b-container>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import { StringValidationUtil } from '@/util/StringValidationUtil'

export default {
  name: 'PasswordResetRequest',
  mixins: [ApiMixin, NotificationMixin],
  data() {
    return {
      userEmail: ''
    }
  },
  computed: {
    emailIsValid() {
      return StringValidationUtil.isValidEmail(this.userEmail)
    }
  },
  methods: {
    requestReset() {
      if (this.userEmail !== '' && this.emailIsValid) {
        let bodyFormData = {
          userEmail: this.userEmail
        }
        let self = this
        this.postRequest(
          '/account/password/begin_reset',
          bodyFormData,
          new RequestConfig().onSuccess(() => {
            // show success message AFTER the redirect, otherwise it flashes only for a sec
            self.$router
              .push({ name: 'login' })
              .then(() => self.displaySuccess(self.$t('passwordReset.reset-request-successful')))
          })
        )
      } else {
        this.displayError(this.$t('passwordReset.email-error'))
      }
    }
  }
}
</script>
